import { ref } from 'vue'
import { expenseOptions } from "@/utils/enum.js"; 
export const waitIptData = ref([
  {
    type: 'input',
    placeholder: '车牌号',
    value: null,
    prop: 'name',
   width: '23%'
  },
  {
    type: 'input',
    placeholder: '业务员',
    value: null,
    prop: 'mobile',
    width: '23%'
  },
  {
    type: 'select',
    placeholder: '线路运输起',
    value: null,
    prop: 'no',
 width: '23%'
  },
  {
    type: 'select',
    placeholder: '线路运输终',
    value: null,
    prop: 'isQuit',
   width: '23%',
    opt:[
      {
        label: '在职',
        value: 0
      },
      {
        label: '离职',
        value: 1
      }
    ]
  },
  {
    type: 'select',
    placeholder: '起运地',
    value: null,
    prop: 'isQuit',
    width: '23%',
    opt:[
      {
        label: '在职',
        value: 0
      },
      {
        label: '离职',
        value: 1
      }
    ]
  },
  {
    type: 'select',
    placeholder: '目的地',
    value: null,
    prop: 'isQuit',
    width: '23%',
  },
  {
    type: 'select',
    placeholder: '车辆状态',
    value: null,
    prop: 'isQuit',
    width: '23%',
  },
 
])
export const alreadyIptData = ref([
  {
    type: 'input',
    placeholder: '大车车牌号',
    value: null,
    prop: 'name',
   width: '23%'
  },
  {
    type: 'input',
    placeholder: '承运商名称',
    value: null,
    prop: 'mobile',
    width: '23%'
  },
  {
    type: 'select',
    placeholder: '线路运输起',
    value: null,
    prop: 'no',
 width: '23%'
  },
  {
    type: 'select',
    placeholder: '线路运输终',
    value: null,
    prop: 'isQuit',
   width: '23%',
    opt:[
      {
        label: '在职',
        value: 0
      },
      {
        label: '离职',
        value: 1
      }
    ]
  },
  {
    type: 'select',
    placeholder: '车辆类型',
    value: null,
    prop: 'isQuit',
    width: '23%',
    opt:[
      {
        label: '在职',
        value: 0
      },
      {
        label: '离职',
        value: 1
      }
    ]
  },
  {
    type: 'select',
    placeholder: '当前状态',
    value: null,
    prop: 'isQuit',
    width: '23%',
  },
  {
    type: 'input',
    placeholder: '发车人',
    value: null,
    prop: 'isQuit',
    width: '23%',
  },
 
])
export const waitColumns =ref([
  {
    title: '大车车牌',
    dataIndex: 'carNo',
    align: 'center',
  },
  {
    title: '司机信息',
    dataIndex: 'driverName',
    slots:{ customRender: 'driverName' },
    align: 'center',
  },
  {
    title: '客户名称',
    dataIndex: 'carrierName',
    align: 'center'
  },
  {
    title: '运费',
    dataIndex: 'transportFee',
    align: 'center',
    slots:{ customRender:'transportFee' }
  },
  {
    title: '是否完成',
    dataIndex: 'complete.label',
    align: 'center'
  },
  {
    title: '运输线路',
    dataIndex: 'startAddress',
    slots:{ customRender:'startAddress' },
    align: 'center'
  },
  {
    title: '装车时间',
    dataIndex: 'loadingTime',
    align: 'center'
  },
  {
    title: '装车人',
    dataIndex: 'loadingOperator',
    align: 'center'
  },
  {
    title: '卸车时间',
    dataIndex: 'unloadingTime',
    align: 'center'
  },
  {
    title: '卸车人',
    dataIndex: 'unloadingOperator',
    align: 'center'
  },
  {
    title: '卸车地',
    dataIndex: 'endAddress',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots:{ customRender:'add'},
    align: 'center',
    fixed:'right'
  },
])
export const alreayColumns =ref([
  {
    title: '大车车牌号',
    dataIndex: 'sort',
    align: 'center',
  },
  {
    title: '承运商名称',
    dataIndex: 'name1',
    align: 'center'
  },
  {
    title: '车辆类型',
    dataIndex: 'name2',
    align: 'center'
  },
  {
    title: '当前状态',
    dataIndex: 'name3',
    align: 'center'
  },
  {
    title: '发车人',
    dataIndex: 'name4',
    align: 'center'
  },
  {
    title: '当前运输路线',
    dataIndex: 'name3',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots:{ customRender:'add'}
  }
])

export const surFormList = ref([
  {
    label: '金额',
    name: 'amount',
    type: 'inputNumber',
    placeholder: '请输入',
    value: null,
    prop: 'amount',
    width: '40%',
    rules: [
      {
        required: true,
        message: '请输入',
        trigger: 'blur',
         type:'number'
      },
    ],
  },
  {
    label: '挂账对象',
    name: 'carrierId',
    type: 'selectSearch',
    placeholder: '请输入',
    value: null,
    prop: 'carrierId',
    width: '40%',
    rules: [
      {
        required: true,
        message: '请搜索选择',
        trigger: 'blur'
      },
    ],
  },
  {
    label: '挂账司机',
    name: 'driverId',
    type: 'select',
    placeholder: '请选择',
    value: null,
    prop: 'driverId',
    width: '40%',
    opt:[]
  },
  {
    label: '费用类型',
    name: 'exceptionType',
    type: 'select',
    placeholder: '请选择',
    value: null,
    prop: 'exceptionType',
    width: '40%',
    rules: [
      {
        required: false,
        message: '请选择',
        trigger: 'blur',
        type:'number'
      },
    ],
    opt:expenseOptions
  },
  {
    label: '备注',
    name: 'exceptionExplain',
    type: 'textarea',
    placeholder: '请输入',
    value: null,
    prop: 'exceptionExplain',
    width: '30%',
    rows:2,
    rules: [
      {
        required: false,
        message: '请输入',
        trigger: 'blur'
      },
    ],
  },
  {
    label: '费用凭证',
    name: 'fileId',
    type: 'upload',
    value: null,
    prop: 'fileId',
    width: '40%',
    rules: [
      {
        required: false,
        message: '请输入',
        trigger: 'blur'
      },
    ],
  },
])